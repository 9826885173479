import { DeviceEntry } from '../devices/device.types';
import { LocationEntry } from '../locations/locations.types';

export enum ArchivedEnum {
  active = 0,
  archived = 1,
  all = 'any'
}

export enum RoleEnum {
  amputee = 'Amputee',
  clinicAdmin = 'ClinicAdmin',
  clinicianSupport = 'ClinicianSupport',
  clinician = 'Clinician',
  superAdmin = 'SuperAdmin'
}

export enum UserExtendOptions {
  clinicians = 'clinicians',
  patients = 'patients',
  devices = 'devices',
  devicesAsClinician = 'devicesAsClinician',
  roles = 'roles',
  permissions = 'permissions',
  creator = 'creator'
}

export enum InvitationStatusEnum {
  pending = 'pending',
  expired = 'expired',
  accepted = 'accepted'
}

export enum UserDevicesExtendOptions {
  model = 'model'
}

export interface Role {
  id: number;
  name: string;
}

export interface PermissionEntry {
  id: number;
  name: string;
  guard_name: string;
  created_at: string;
  updated_at: string;
}

export type InvitationType = {
  user_id?: string;
  user_email?: string;
  role?: string;
  training_confirmed: number;
  permissions?: string[];
};

export interface InvitaionsPayload {
  patient_id: string;
  invitations: InvitationType[];
}

export interface UserCreatePayload {
  name: string;
  mrn?: string;
  email: string;
  phone: string;
  phone_country: string | null;
  clinic_name?: string;
  clinic_location?: string;
  location: string;
  role: string;
  image: File;
  address1: string;
  address2: string;
  permissions: any;
}

export interface UserUpdatePayload extends UserCreatePayload {
  id: number;
  image_delete: boolean;
  language?: string;
}

export interface UserEntry {
  id: number;
  name: string;
  email: string;
  mrn: string;
  language: string;
  phone: string;
  phone_country: string | null;
  phone_verified_at: string;
  password?: string;
  image?: string;
  clinic_name?: string;
  clinic_location?: string;
  location_id?: number;
  created_by?: number;
  archived: ArchivedEnum;
  created_at: string;
  updated_at: string;
  location?: LocationEntry;
  roles?: Role[];
  devices?: DeviceEntry[];
  devices_as_clinician?: DeviceEntry[];
  permissions?: PermissionEntry[];
  address1?: string;
  address2?: string;
  creator?: UserEntry;
  clinicians?: UserEntry[];
  notifications_timezone?: string;
  has_access_to_my_patients?: 0 | 1;
  role_name?: string;
  patients?: UserEntry[];
  invitation_status?: InvitationStatusEnum | null;
}

export interface UsersQueryParams {
  archived?: ArchivedEnum;
  company?: number;
  location?: number;
  clinician?: number;
  roles?: RoleEnum[] | string;
  extend?: UserExtendOptions[];
  perpage?: number;
  page?: number;
  search?: string;
  active?: string;
}

export interface UserQueryParams {
  extend?: UserExtendOptions[];
}

export interface UserDevicesParams {
  perpage?: number;
  page?: number;
  extend?: UserDevicesExtendOptions[];
}

export interface UsersInvitedQueryParams {
  perpage?: number;
  page?: number;
  active?: string;
  extend?: UserExtendOptions[];
}
