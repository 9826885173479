import React from 'react';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import { Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ModalStyle } from './styles';
import FormButtonsWrapper from '../FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';

type ConfirmDeleteModalProps = {
  handleClose: any;
  handleAccept: any;
  message: React.ReactNode;
  isLoading: boolean;
  acceptMessage?: string;
  title?: string;
};

export const ModalMessageDelete = ({
  name,
  id,
  text
}: {
  name?: string;
  id?: number;
  text: string;
}) => (
  <>
    {text} <strong>{name}</strong> (ID: {id})?
  </>
);

export const ModalMessageDevice = ({ id }: { id: number }) => {
  const { t } = useTranslation('devices');
  return (
    <>
      {t(
        'devices:confirm_delete_modal.device_message',
        'Do you want to delete device (ID: {{id}})?',
        { id }
      )}
    </>
  );
};

export const ModalMessageMode = ({ id }: { id: number }) => {
  const { t } = useTranslation('devices');
  return (
    <>
      {t('devices:confirm_delete_modal.mode_message', 'Do you want to delete mode (ID: {{id}})?', {
        id
      })}
    </>
  );
};

export const ModalMessageReleaseNote = ({ id }: { id: number }) => {
  const { t } = useTranslation('devices');
  return (
    <>
      {t(
        'devices:confirm_delete_modal.mapping_message',
        'Do you want to delete mapping (ID: {{id}})?',
        { id }
      )}
    </>
  );
};

const ConfirmDeleteModal = ({
  handleClose,
  message,
  handleAccept,
  isLoading,
  title
}: ConfirmDeleteModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal open={true} sx={{ width: '480px', ...ModalStyle }}>
      <FormContentWrapper
        title={
          title ? title : t('common:confirm_delete_modal.title', 'Remove device from my view')
        }>
        <p>{message}</p>
        <FormButtonsWrapper>
          <CustomButton color='light' onClick={handleClose}>
            {t('common:confirm_delete_modal.cancel', 'Cancel')}
          </CustomButton>
          <CustomButton onClick={handleAccept} loading={isLoading}>
            {t('common:confirm_delete_modal.confirm', 'Confirm')}
          </CustomButton>
        </FormButtonsWrapper>
      </FormContentWrapper>
    </Modal>
  );
};

export default ConfirmDeleteModal;
