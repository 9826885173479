import { encodeQueryData } from '../utils/encodeQuery';
import { AxiosResponse } from 'axios';
import api, { withCredentials, withCredentialsAndMultipartForm } from '../utils/apiClient';
import { PaginatedResponse } from '../../../types';
import {
  InvitaionsPayload,
  UserCreatePayload,
  UserEntry,
  UserQueryParams,
  UsersQueryParams,
  UserUpdatePayload
} from './users.types';
import { convertToFormData } from '../utils/apiUtils';
import { NotificationFactory } from 'lib/NotificationFactory';
import i18n from 'i18n';

export const GET_USERS = 'api/users';
export const GET_USER = 'api/user';
export const GET_USER_ME = 'api/me';
export const CREATE_USER = 'api/user';
export const INVITE_USERS = 'api/invite';
export const DELETE_USER = 'api/user/{id}';
export const UPDATE_USER = 'api/user/{id}';
export const UPDATE_USER_PHONE = 'api/user/{id}/phone';
export const CHANGE_PASSWORD = 'api/user/{id}/password';
export const RESEND_INVITATION = 'api/invite/{id}/resend';
export const DETACH_USER = 'api/user/{id}/detach';
export const GET_INVITED_USERS = 'api/invite/users';

export const getUsers = (params: UsersQueryParams = {}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params
  });
  return api
    .get<PaginatedResponse<UserEntry>>(`${GET_USERS}?${queryString}`, withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const getInvitedUsers = (params: UsersQueryParams = {}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params
  });
  return api
    .get<PaginatedResponse<UserEntry>>(`${GET_INVITED_USERS}?${queryString}`, withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const getUserMe = ({ params = {} }: { params?: UserQueryParams }): Promise<UserEntry> => {
  return api
    .get<UserEntry>(
      `${GET_USER_ME}?${encodeQueryData({
        ...params,
        extend: params.extend?.toString()
      })}`,
      withCredentialsAndMultipartForm
    )
    .then((res: AxiosResponse) => res.data);
};

export const getUser = (
  id: number | string,
  { params = {} }: { params?: UserQueryParams }
): Promise<UserEntry> => {
  return api
    .get<UserEntry>(
      `${GET_USER}/${id}?${encodeQueryData({
        ...params,
        extend: params.extend?.toString()
      })}`,
      withCredentialsAndMultipartForm
    )
    .then((res: AxiosResponse) => res.data);
};

export const addUser = (data: UserCreatePayload) => {
  return api
    .post<UserEntry>(CREATE_USER, convertToFormData(data), withCredentialsAndMultipartForm)
    .then((res: AxiosResponse<UserEntry>) => res.data);
};

export const inviteUsers = (data: InvitaionsPayload) => {
  return api
    .post<UserEntry>(INVITE_USERS, convertToFormData(data), withCredentialsAndMultipartForm)
    .then((res: AxiosResponse<UserEntry>) => res.data);
};

export const updateUser = (data: UserUpdatePayload) => {
  return api
    .post<UserEntry>(UPDATE_USER.replace('{id}', `${data.id}`), convertToFormData(data), {
      ...withCredentialsAndMultipartForm,
      ...{ params: { _method: 'put' } }
    })
    .then((res: AxiosResponse<UserEntry>) => res.data);
};

export const deleteUser = (userId: number | string) => {
  return api
    .delete<UserEntry>(DELETE_USER.replace('{id}', `${userId}`), withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const changePasswordForAnotherUser = ({
  userId,
  password
}: {
  userId: number;
  password: string;
}) => {
  return api
    .post(CHANGE_PASSWORD.replace('{id}', `${userId}`), { password }, withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const updateUserPhone = ({
  userId,
  phone,
  phone_country,
  resend_code = false
}: {
  userId: number;
  phone: string;
  phone_country: string | null;
  resend_code?: boolean;
}) => {
  return api
    .post(UPDATE_USER_PHONE.replace('{id}', `${userId}`), { phone, phone_country }, withCredentials)
    .then((res: AxiosResponse<UserEntry>) => {
      if (phone !== '0')
        NotificationFactory.successNotification(
          i18n.t('common:api_notification.code_send', 'Code send')
        );
      return res.data;
    });
};

export const resendInvitation = ({ userId }: { userId: number }) => {
  return api
    .post(RESEND_INVITATION.replace('{id}', `${userId}`), {}, withCredentials)
    .then((res: AxiosResponse<UserEntry>) => res.data);
};

export const detachUser = (userId) => {
  return api
    .post(DETACH_USER.replace('{id}', `${userId}`), {}, withCredentials)
    .then((res: AxiosResponse<UserEntry>) => res.data);
};
