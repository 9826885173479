/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  DialogContent,
  MenuItem,
  TextField,
  Tooltip,
  Pagination,
  Badge,
  CircularProgress
} from '@mui/material';
import {
  CONFIG_ENTRY_NOTES_KEY,
  useConfigEntryNotes,
  useCreateConfigNote,
  useDeleteConfigNote,
  useDeviceConfigHistory,
  useDeviceConfigHistoryInfinite
} from 'configurator/hooks/api/useDevice';
import { MONTHS, months, YEARS } from 'configurator/consts/consts';
import Calendar from 'configurator/components/organisms/Calendar/Calendar';
import { getYears, groupConfigEntries } from 'configurator/utils/Calendar/CalendarUtils';
import InnerCard from 'configurator/components/atoms/Card/InnerCard';
import { useModal } from 'configurator/hooks/useModal';
import ModalPortal from 'configurator/utils/Modal/ModalPortal';
import { AvatarSnack } from 'configurator/components/atoms/AvatarSnack/AvatarSnack';
import RestoreConfigHistoryModal from 'configurator/modals/RestoreConfigHistoryModal';
import {
  StyledEye,
  StyledNotesIcon,
  StyledUndo,
  StyledPlus
} from 'configurator/components/atoms/Icons/Icons';
import {
  ConfigNotesSortOptions,
  ConfigHistoryEntry,
  ConfigHistoryItemEntry
} from 'configurator/api/device/device.types';
import { SortDirs } from 'configurator/utils/types';
import Divider from 'configurator/components/atoms/Divider/Divider';
import { Table } from 'configurator/components/atoms/Table/Table';
import NotesList from 'configurator/components/organisms/NotesList/NotesList';
import { useUiStore } from 'configurator/reducers/uiStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import CalendarPicker from 'configurator/components/molecules/CalendarPicker/CalendarPicker';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import {
  findDifferencesImport,
  transformConfigAPI
} from 'configurator/utils/Config/transformConfig';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceManager } from 'configurator/hooks/api/useDeviceManager';
import ConfirmationLoader from 'configurator/layouts/ConfirmationLoader/ConfirmationLoader';
import useImportButton from 'configurator/hooks/useImportButton';
import AddNote from 'configurator/modals/AddNoteModal';
import { TextStyled } from 'configurator/views/Templates/ConfigTemplates/ConfigTemplatesComponent';
import { HeaderWrapper } from '../styled';
import { CheckComponent, ToggleHistoryView } from '../components';
import { DEVICE_HISTORY } from 'constants/routes';
import ModalBase from 'configurator/modals/ModalBase';
import CustomPageHeader from 'configurator/components/atoms/Typography/CustomHeader';
import Card from 'adp-panel/components/Card/Card';
import CustomButton from 'components/Button/CustomButton';
import { ReactComponent as Eye } from 'assets/eye-solid.svg';
import { ReactComponent as Undo } from 'assets/undo-solid.svg';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { DatePicker } from '@mui/x-date-pickers';
import { myTheme } from 'theme/theme';
import LoaderWrapper from 'components/Loader/Loader';

const SelectorWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const HeaderInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
  flex-wrap: wrap;
`;

const ChangeItemWrapper = styled.tr`
  td {
    word-wrap: break-word;

    &:not(:last-child) {
      padding-right: 12px;
    }
  }
`;

enum sortByOptions {
  latest = 'desc',
  earliest = 'asc'
}

enum filterByOptions {
  noFilter = 'null',
  restorePoint = '1'
}

const sortOptions = [
  { id: sortByOptions.latest, name: 'Latest' },
  { id: sortByOptions.earliest, name: 'Earliest' }
];

const filterOptions = [
  { id: filterByOptions.noFilter, name: 'No filter' },
  { id: filterByOptions.restorePoint, name: 'Restore point' }
];

const ChangeItem = ({ changeEntry, deviceId }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleClose, handleOpen, isOpen } = useModal();
  const {
    handleClose: handleCloseAddNote,
    handleOpen: handleOpenAddNote,
    isOpen: isOpenAddNote
  } = useModal();
  const {
    handleClose: handleCloseRestoreConfigHistoryModal,
    handleOpen: handleOpenRestoreConfigHistoryModal,
    isOpen: isRestoreConfigHistoryModalOpen
  } = useModal();
  const { common, modes } = useConfigStore((state: any) => ({
    modes: state.config.modes,
    common: state.config.common
  }));
  const { restoreConfigHistory, isLoadingDeviceManager } = useDeviceManager();
  const { parsedConfig } = transformConfigAPI(changeEntry, modes);
  const { result: notes } = useConfigEntryNotes({
    deviceId,
    configId: changeEntry.id,
    params: { sortby: ConfigNotesSortOptions.date, sortdir: SortDirs.desc }
  });
  const { mutateAsync: createConfigNote, isLoading: isLoadingCreateConfigNote } =
    useCreateConfigNote();
  const { mutateAsync: deleteConfigNote } = useDeleteConfigNote();
  const queryClient = useQueryClient();
  const importDifferences = findDifferencesImport(common, modes, parsedConfig);
  const { importTooltip, disableImportButton } = useImportButton(importDifferences);

  const handlePush = (configId: number) => {
    navigate(`${DEVICE_HISTORY}/${configId}`, { state: { detail: changeEntry } });
  };

  const handleRestore = async () => {
    await restoreConfigHistory(changeEntry.id);
    handleCloseRestoreConfigHistoryModal();
  };

  const handleAddNote = async ({ note, type }: any) => {
    await createConfigNote({ deviceId, configId: Number(changeEntry.id), data: { note, type } });
    queryClient.invalidateQueries([CONFIG_ENTRY_NOTES_KEY]);
  };

  const handleDeleteNote = async ({ noteId }: any) => {
    await deleteConfigNote({ deviceId, configId: Number(changeEntry.id), noteId });
    queryClient.invalidateQueries([CONFIG_ENTRY_NOTES_KEY]);
  };

  return (
    <>
      {isRestoreConfigHistoryModalOpen && (
        <RestoreConfigHistoryModal
          handleClose={handleCloseRestoreConfigHistoryModal}
          handleAccept={handleRestore}
          entry={parsedConfig}
          isLoading={isLoadingDeviceManager}
        />
      )}
      {isOpen && (
        <ModalPortal>
          <ModalBase
            handleClick={handleClose}
            header={t('configurator:component.device_history.notes', 'Notes')}
            fullWidth>
            <DialogContent>
              <NotesList notes={notes} handleDelete={handleDeleteNote} />
            </DialogContent>
          </ModalBase>
        </ModalPortal>
      )}
      {isOpenAddNote && (
        <ModalPortal>
          <AddNote
            handleClose={handleCloseAddNote}
            handleSubmitNote={handleAddNote}
            isLoading={isLoadingCreateConfigNote}
          />
        </ModalPortal>
      )}
      <ChangeItemWrapper>
        <td aria-label={t('configurator:component.device_history.author_name', 'Author name')}>
          <span>{changeEntry?.author?.name}</span>
        </td>
        <td>{changeEntry?.restore_point === 0 ? '-' : <CheckComponent />}</td>
        <td>
          <TextStyled>{notes?.[0]?.note}</TextStyled>
        </td>
        <td>{dayjs(changeEntry?.created_at).format('MM.DD.YYYY, HH:mm')}</td>
        <td>
          <ActionsWrapper>
            <Tooltip title={importTooltip()}>
              <CustomButton
                onClick={() => handlePush(changeEntry.id)}
                Icon={Undo}
                color='light'
                iconProps={{ width: '16px' }}
                disabled={disableImportButton()}>
                {t('configurator:component.device_history.button.import', 'Import')}
              </CustomButton>
            </Tooltip>
            <CustomButton
              onClick={() => handlePush(changeEntry.id)}
              Icon={Eye}
              color='light'
              iconProps={{ width: '16px' }}>
              {t('configurator:component.device_history.button.see', 'See')}
            </CustomButton>
            {notes?.length > 0 && (
              <Button onClick={handleOpen} size='small'>
                <StyledNotesIcon style={{ marginRight: '9px' }} />
                {t('configurator:component.device_history.button.show_notes', 'Show notes')}
              </Button>
            )}
            <CustomButton
              onClick={handleOpenAddNote}
              Icon={StickyNote2OutlinedIcon}
              color='light'
              iconProps={{ sx: { width: '18px', height: '18px', padding: 0 } }}>
              {t('configurator:component.device_history.button.add_note', 'Add note')}
            </CustomButton>
          </ActionsWrapper>
        </td>
      </ChangeItemWrapper>
    </>
  );
};

const itemsPerPage = 10;

function ConfigDay(
  props: PickersDayProps<Dayjs> & {
    events?: { day: number; month: number; year: number; events: number }[];
  }
) {
  const { events = [], day, outsideCurrentMonth, ...other } = props;

  const selectedEvent = events.find(
    (event) =>
      event.day === props.day.date() &&
      event.month === props.day.month() &&
      event.year === props.day.year()
  );
  const isSelected = !props.outsideCurrentMonth && selectedEvent;

  return (
    <Tooltip title={isSelected ? `${selectedEvent.events} configurations` : undefined}>
      <div style={{ position: 'relative' }}>
        {isSelected && (
          <div
            style={{
              position: 'absolute',
              backgroundColor: myTheme.palette.primary,
              width: '4px',
              height: '4px',
              borderRadius: '50%',
              left: '50%',
              top: '18%',
              transform: 'translateX(-50%) translateY(-50%)'
            }}
          />
        )}
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </div>
    </Tooltip>
  );
}

const DeviceHistoryComponent = () => {
  const { t } = useTranslation();
  const isCalendarHistory = useUiStore((state: any) => state.isCalendarHistory);
  const deviceId = useDeviceInfoStore((state: any) => Number(state.deviceId));
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month());
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const { result: devicesConfigHistory, isLoading: isLoadingDevicesHistory } =
    useDeviceConfigHistory(deviceId, {
      extend: 'author,entries',
      sortby: 'date',
      sortdir: sortByOptions.latest,
      perpage: 1000
    });
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBySelected, setSortBySelected] = useState(sortByOptions.latest);
  const [filterBySelected, setFilterBySelected] = useState(filterByOptions.noFilter);
  const [dateSelected, setDateSelected] = useState<number | null>(null);
  const { result: devicesConfigHistoryLog, isLoading: isLoadingDevicesConfigHistoryLog } =
    useDeviceConfigHistoryInfinite(deviceId, {
      extend: 'author,entries',
      perpage: 100,
      sortby: 'date',
      sortdir: sortBySelected,
      restore_point: filterBySelected,
      ...(dateSelected && {
        date_from: dateSelected,
        date_to: dateSelected + 24 * 60 * 60
      })
    });
  const { result: devicesConfigHistoryCalendar } = useDeviceConfigHistory(deviceId, {
    extend: 'author,entries',
    perpage: 10000
  });
  const { setItemUiStore } = useUiStore();

  const currentPageFirsItemIndex = (currentPage - 1) * itemsPerPage;
  const currentPageLastItemIndex = (currentPage - 1) * itemsPerPage + itemsPerPage;

  const isAutomaticallyCreated = (changeEntries: ConfigHistoryEntry[]) =>
    !changeEntries.find((entry) => Boolean(JSON.parse(entry.old_value)) === true) ||
    changeEntries.length === 0;

  const deviceConfigHistoryFilter = (configHistoryItemEntries: ConfigHistoryItemEntry[]) =>
    configHistoryItemEntries.filter(
      (historyEntry) => historyEntry.index !== 1 && !isAutomaticallyCreated(historyEntry.entries)
    );

  const devicesConfigHistoryLogFiltered = (sliced = true) => {
    if (devicesConfigHistoryLog) {
      const initialFilter = deviceConfigHistoryFilter(devicesConfigHistoryLog);

      return sliced
        ? initialFilter.slice(currentPageFirsItemIndex, currentPageLastItemIndex)
        : initialFilter;
    }

    return [];
  };

  const devicesConfigHistoryFiltered = {
    ...devicesConfigHistory,
    items: devicesConfigHistory?.items ? deviceConfigHistoryFilter(devicesConfigHistory?.items) : []
  };

  const devicesConfigHistoryCalendarFiltered = {
    ...devicesConfigHistoryCalendar,
    items: devicesConfigHistoryCalendar?.items
      ? deviceConfigHistoryFilter(devicesConfigHistoryCalendar?.items)
      : []
  };

  const lastPage = Math.ceil(devicesConfigHistoryLogFiltered(false)?.length / itemsPerPage);

  const handleApply = (selectedDate: Dayjs | null) => {
    if (!selectedDate) {
      setDateSelected(null);
      return;
    }

    const dateFrom = selectedDate.hour(0).minute(0).second(0).millisecond(0);
    setDateSelected(dateFrom.unix());
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const isLoading = isLoadingDevicesConfigHistoryLog || isLoadingDevicesHistory;

  const CalendarHeader = (
    <>
      <CustomPageHeader
        header={t('configurator:component.device_history.changes_history', 'Changes history')}
        tooltipText={t('configurator:header.device_history.tooltip', 'Tooltip')}
      />
      <HeaderInnerWrapper>
        <SelectorWrapper>
          <TextField
            sx={{ width: 150 }}
            select
            label={t('configurator:component.device_history.month', 'Month')}
            id='select-month'
            SelectProps={{
              onChange: (e: any) => setSelectedMonth(e.target.value),
              value: selectedMonth
            }}>
            {MONTHS.map((month) => (
              <MenuItem key={`select-month_${month.id}`} value={month.id}>
                {t(month.name)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            sx={{ width: 150 }}
            select
            label={t('configurator:component.device_history.year', 'Year')}
            id='select-year'
            SelectProps={{
              onChange: (e: any) => setSelectedYear(e.target.value),
              value: selectedYear
            }}>
            {getYears(dayjs).map((year) => (
              <MenuItem key={`select-year_${year.id}`} value={year.id}>
                {year.name}
              </MenuItem>
            ))}
          </TextField>
        </SelectorWrapper>
        <ToggleHistoryView
          status={isCalendarHistory}
          handler={() => setItemUiStore('isCalendarHistory', !isCalendarHistory)}
        />
      </HeaderInnerWrapper>
    </>
  );

  const events = groupConfigEntries(devicesConfigHistoryCalendarFiltered)
    .map((event) => event.map((_event) => dayjs(_event.created_at)))
    .map((parsedEvent) => ({
      day: parsedEvent[0].date(),
      month: parsedEvent[0].month(),
      year: parsedEvent[0].year(),
      events: parsedEvent.length
    }));

  const LogsHeader = () => (
    <>
      <CustomPageHeader
        header={t('configurator:component.device_history.changes_history', 'Changes history')}
        tooltipText={t('configurator:header.device_history.tooltip', 'Tooltip')}
      />
      <HeaderInnerWrapper>
        {devicesConfigHistoryCalendarFiltered && (
          <DatePicker
            value={dateSelected ? dayjs.unix(dateSelected) : null}
            slots={{
              day: ConfigDay
            }}
            onChange={handleApply}
            slotProps={{
              textField: { size: 'small' },
              day: { events } as any,
              actionBar: {
                actions: ['clear']
              }
            }}
            sx={{ backgroundColor: '#FFF' }}
          />
        )}
        <TextField
          sx={{ width: 150 }}
          select
          label={t('configurator:component.device_history.sort_by', 'Sort by')}
          id='select-sort-by'
          SelectProps={{
            onChange: (e: any) => setSortBySelected(e.target.value),
            value: sortBySelected
          }}>
          {sortOptions.map((option) => (
            <MenuItem key={`select-sort-by_${option.id}`} value={option.id}>
              {t(
                `configurator:component.device_history.sort_by_${option.name.toLowerCase()}`,
                option.name
              )}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          sx={{ width: 150 }}
          select
          label={t('configurator:component.device_history.filter_by', 'Filter by')}
          id='select-filter-by'
          SelectProps={{
            onChange: (e: any) => setFilterBySelected(e.target.value),
            value: filterBySelected
          }}>
          {filterOptions.map((option) => (
            <MenuItem key={`select-filter-by_${option.id}`} value={option.id}>
              {t(
                `configurator:component.device_history.filter_by_${option.name.toLowerCase()}`,
                option.name
              )}
            </MenuItem>
          ))}
        </TextField>
        <ToggleHistoryView
          status={isCalendarHistory}
          handler={() => setItemUiStore('isCalendarHistory', !isCalendarHistory)}
        />
      </HeaderInnerWrapper>
    </>
  );

  const CalendarView = (
    <>
      {devicesConfigHistoryFiltered?.items && (
        <Calendar
          month={dayjs().year(selectedYear).month(selectedMonth).date(1)}
          events={groupConfigEntries(devicesConfigHistoryFiltered)}
          months={months}
        />
      )}
    </>
  );

  const LogsView = devicesConfigHistoryLogFiltered() && (
    <>
      <InnerCard>
        <Table>
          <tr>
            <th style={{ width: '20%' }}>
              {t('configurator:component.device_history.author_name', 'Author name')}
            </th>
            <th style={{ width: '12%' }}>
              {t('configurator:component.device_history.restore_point', 'Restore point')}
            </th>
            <th>{t('configurator:component.device_history.note', 'Note')}</th>
            <th style={{ width: '20%' }}>
              {t('configurator:component.device_history.created_at', 'Created at')}
            </th>
            <th style={{ width: '28%' }}>
              {t('configurator:component.device_history.actions', 'Actions')}
            </th>
          </tr>
          {devicesConfigHistoryLogFiltered().map((deviceConfigHistoryEntry) => (
            <ChangeItem
              changeEntry={deviceConfigHistoryEntry}
              deviceId={deviceId}
              key={`entry_${deviceConfigHistoryEntry?.id}`}
            />
          ))}
        </Table>
      </InnerCard>
      <Divider margin='20px' />
      <PaginationWrapper>
        <Pagination
          count={lastPage}
          shape='rounded'
          page={currentPage}
          onChange={handleChangePage}
        />
      </PaginationWrapper>
    </>
  );

  if (isLoading) {
    return (
      <LoaderWrapper>
        <CircularProgress />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <HeaderWrapper>{isCalendarHistory ? CalendarHeader : <LogsHeader />}</HeaderWrapper>
      <Card>{isCalendarHistory ? CalendarView : LogsView}</Card>
    </>
  );
};

export default DeviceHistoryComponent;
