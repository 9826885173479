import { getCompatibilities } from 'configurator/api/versions/versions';
import {
  CompatibilitiesQueryParams,
  CompatibilitiesExtendOptions,
  CompatibilityEntry,
  FeaturesEntry
} from 'configurator/api/versions/versions.types';
import { DeviceQueryParams, DeviceExtendOptions } from 'configurator/api/device/device.types';
import { getDevice } from 'configurator/api/device/device';

export const shortenString = (string, maxLength) => {
  if (!string || string?.length === 0) return '';

  return string.length > maxLength ? string.slice(0, maxLength - 1) + '...' : string;
};

const SORT_DIRS = {
  ascending: -1,
  descending: 1
};

export const getCompatibility = async ({ deviceId }) => {
  const deviceQueryParams: DeviceQueryParams = {
    extend: [
      DeviceExtendOptions.firmwareVersion,
      DeviceExtendOptions.pcbVersion,
      DeviceExtendOptions.amputee
    ]
  };
  const deviceInfo = await getDevice(deviceId, deviceQueryParams);

  const compatibilitiesParamsFirmware: CompatibilitiesQueryParams = {
    model: deviceInfo.model_id || undefined,
    software: process.env.REACT_APP_SOFTWARE_VERSION,
    pcb: deviceInfo.pcb_version.name,
    extend: [CompatibilitiesExtendOptions.features, CompatibilitiesExtendOptions.firmwareVersion]
  };
  const compatibilitiesParams: CompatibilitiesQueryParams = {
    model: deviceInfo.model_id || undefined,
    software: process.env.REACT_APP_SOFTWARE_VERSION,
    firmware: deviceInfo.firmware_version?.name,
    pcb: deviceInfo.pcb_version.name,
    extend: [CompatibilitiesExtendOptions.features]
  };
  const compatibilitiesParamsPCB: CompatibilitiesQueryParams = {
    model: deviceInfo.model_id || undefined,
    software: process.env.REACT_APP_SOFTWARE_VERSION,
    firmware: deviceInfo.firmware_version?.name,
    extend: [CompatibilitiesExtendOptions.features, CompatibilitiesExtendOptions.pcbVersion]
  };

  const compatibilityListFirmware = await getCompatibilities({
    params: compatibilitiesParamsFirmware
  });
  const compatibilityInfo = await getCompatibilities({ params: compatibilitiesParams });
  const compatibilityListPCB = await getCompatibilities({ params: compatibilitiesParamsPCB });

  const versions = compatibilityListFirmware?.items
    .filter(
      (item: CompatibilityEntry) =>
        item.is_fully_compatible === 1 ||
        item.features?.find((feature) => Boolean(feature.is_compatible))
    )
    .sort((a, b) => {
      const parseFirmware = (firmwareName) => Number(firmwareName.replaceAll('.', ''));
      const parsedFirmwareA = parseFirmware(a.firmware_version.name);
      const parsedFirmwareB = parseFirmware(b.firmware_version.name);

      if (parsedFirmwareA > parsedFirmwareB) return SORT_DIRS.ascending;
      return SORT_DIRS.descending;
    })
    .map((item) => item.firmware_version);
  const availableFirmwares = compatibilityListFirmware?.items
    .filter(
      (item: CompatibilityEntry) =>
        item.is_fully_compatible === 1 ||
        item.features?.find((feature) => Boolean(feature.is_compatible))
    )
    .sort((a, b) => {
      const parseFirmware = (firmwareName) => Number(firmwareName.replaceAll('.', ''));
      const parsedFirmwareA = parseFirmware(a.firmware_version.name);
      const parsedFirmwareB = parseFirmware(b.firmware_version.name);

      if (parsedFirmwareA > parsedFirmwareB) return SORT_DIRS.ascending;
      return SORT_DIRS.descending;
    })
    .map((item) => item.firmware_version);

  const currentCompatibility: CompatibilityEntry = compatibilityInfo?.items?.[0];

  const isCompatible = currentCompatibility
    ? Boolean(
        currentCompatibility?.is_fully_compatible ||
          currentCompatibility?.features?.find((feature) => Boolean(feature.is_compatible))
      )
    : true;

  let isPcbUpgradeNeeded = false;
  let isFirmwareUpdateAvailable = false;
  let isFirmwareUpdateNeeded = false;

  if (compatibilityListPCB && !isCompatible) {
    isPcbUpgradeNeeded =
      compatibilityListPCB?.items.find(
        (item: CompatibilityEntry) =>
          item.is_fully_compatible === 1 ||
          item.features?.find((feature) => Boolean(feature.is_compatible))
      )?.length > 0;
  }

  if (
    compatibilityListFirmware &&
    availableFirmwares.length > 0 &&
    isCompatible &&
    deviceInfo.firmware_version?.name !== availableFirmwares?.[0].name
  ) {
    isFirmwareUpdateAvailable = true;
  } else {
    isFirmwareUpdateAvailable = false;
  }

  if (compatibilityListFirmware && availableFirmwares.length > 0 && !isCompatible) {
    isFirmwareUpdateNeeded = true;
  } else {
    isFirmwareUpdateNeeded = false;
  }

  return {
    isPcbUpgradeNeeded,
    isFirmwareUpdateAvailable,
    isFirmwareUpdateNeeded,
    firmwareName: deviceInfo.firmware_version?.name,
    latestFirmware: availableFirmwares?.[0]?.name
  };
};
