import { Visibility, VisibilityOff } from '@mui/icons-material';
import { codeIconMap, CountryCode } from 'adp-panel/constants/countries';
import React, { ReactElement } from 'react';
import {
  Autocomplete,
  InputLabel,
  TextField,
  Typography,
  CircularProgress,
  Avatar,
  Box,
  InputAdornment,
  IconButton
} from '@mui/material';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { commonFormStyles, LabelStyle, LabelWrapper } from './commonStyles';

const StyledFlag = styled.div`
  line-height: 0;
  display: inline-block;
  margin-right: 8px;
  border-radius: 50%;
  transition: linear 0.25s;
  height: 20px;
  width: 20px;

  img,
  svg {
    width: 20px;
    height: auto;
    border-radius: 50%;
  }
`;

export interface LanguageType {
  code: string;
  label: string;
  icon: ReactElement | undefined;
}

export const languages: readonly LanguageType[] = [
  {
    label: 'English',
    code: 'en',
    icon: codeIconMap.get('EN')
  },
  {
    label: 'Polish',
    code: 'pl',
    icon: codeIconMap.get('PL')
  },
  {
    label: 'Ukraine',
    code: 'ua',
    icon: codeIconMap.get('UA')
  },
  {
    label: 'Russian',
    code: 'ru',
    icon: codeIconMap.get('RU')
  },
  {
    label: 'Spanish',
    code: 'es',
    icon: codeIconMap.get('ES')
  },
  {
    label: 'German',
    code: 'de',
    icon: codeIconMap.get('DE')
  },
  {
    label: 'Italian',
    code: 'it',
    icon: codeIconMap.get('IT')
  }
];
const CustomLanguageSelect = ({
  id,
  label,
  control,
  optional,
  showLabel,
  loading,
  filterOptions
}: {
  id: string;
  label: string;
  control: any;
  optional: boolean;
  showLabel: boolean;
  loading: boolean;
  filterOptions?: Array<{ code: string }>;
}) => {
  const filteredLanguages =
    filterOptions && filterOptions.length > 0
      ? languages.filter((language) =>
          filterOptions.some((filter) => filter.code === language.code)
        )
      : languages;

  return (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => (
        <div>
          {showLabel && (
            <div style={{ display: 'flex', gap: '8px' }}>
              <InputLabel
                shrink={false}
                htmlFor={id}
                sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
                <LabelWrapper>
                  <Typography sx={{ ...LabelStyle }}>{label}</Typography>
                  {optional && <Typography>(optional)</Typography>}
                </LabelWrapper>
              </InputLabel>
              {loading && <CircularProgress size={20} />}
            </div>
          )}
          <Autocomplete
            {...field}
            id={id}
            options={filteredLanguages}
            autoHighlight
            clearIcon={false}
            onChange={(e, data) => {
              field.onChange(data);
            }}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <Box key={key} component='li' {...optionProps}>
                  <StyledFlag>{option.icon}</StyledFlag>
                  {option.label}
                </Box>
              );
            }}
            renderInput={(params) => (
              <div style={{ position: 'relative' }}>
                <TextField
                  {...params}
                  size='small'
                  variant='outlined'
                  fullWidth
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  id={id}
                  name={id}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      paddingLeft: '18px !important'
                    }
                  }}
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      startAdornment: (() => {
                        const language = languages.find(
                          (lang) => lang.label === params.inputProps.value
                        );
                        return language ? (
                          <InputAdornment position='start'>
                            <StyledFlag style={{ marginRight: 0 }}>{language.icon}</StyledFlag>
                          </InputAdornment>
                        ) : null;
                      })()
                    }
                  }}
                />
              </div>
            )}
          />
        </div>
      )}
    />
  );
};

export default CustomLanguageSelect;
