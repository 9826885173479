import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import EllipseShort from 'assets/ellipse-short.svg';
import DropdownImg from 'configurator/components/atoms/Dropdown/DropdownImg';
import {
  gripsGroupsOptionsMap,
  gripsGroupsOptionsReversedMap
} from 'configurator/utils/definesLocal';
import { Grips } from '../../../../bluetooth/Bluetooth/Grips';
import Card from 'adp-panel/components/Card/Card';
import GripChooserTreePairing from './GripChooserTreePairing';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { GripSwitchingModeIndicator } from '../GripSwitchingModeIndicator/GripSwitchingModeIndicator';
import { getGripName } from 'configurator/views/GripsConfiguration/GripsConfigurationComponent';

const DropdownsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0% 1fr 1fr;
  grid-gap: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    grid-template-columns: 1fr 1fr 6% 1fr 1fr;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    padding: 0 10px;
  }
`;

type GraphChooserTreeProps = {
  id?: string;
  grips: Grips[];
  values: Grips[];
  nonOpposed?: boolean;
  onChange: Function;
  opened: number | null;
  updateOpened?: Function;
  indexes: number[];
  gripsImgs: any;
  disabled: boolean;
  customGripsAllowed: any;
};

const GraphChooserTree = ({
  grips,
  values,
  nonOpposed = false,
  onChange,
  updateOpened = () => false,
  opened = null,
  indexes = [0],
  gripsImgs,
  disabled,
  customGripsAllowed,
  ...props
}: GraphChooserTreeProps) => {
  const { t } = useTranslation();
  const updateGrips = (option, gripOrder, isNonOpposed) => {
    let newGrip: any = gripsGroupsOptionsReversedMap.get(option);

    if (!newGrip && newGrip !== 0) {
      newGrip = customGripsAllowed?.find((customGrip) => customGrip?.name === option)?.grip_number;
    }
    onChange(newGrip, gripOrder, isNonOpposed);
  };

  return (
    <ArcherContainer>
      <Card {...props}>
        <GripChooserTreePairing
          relations={[
            ['dropdown-0', 'dropdown-1'],
            ['dropdown-2', 'dropdown-3']
          ]}
          opposed={!nonOpposed}
        />
        <div style={{ position: 'relative' }}>
          <img
            src={EllipseShort}
            style={{
              width: '20%',
              position: 'absolute',
              left: '13%',
              top: '-7.5%'
            }}
          />
          <img
            src={EllipseShort}
            style={{
              width: '20%',
              position: 'absolute',
              right: '13%',
              top: '-7.5%'
            }}
          />
          <GripSwitchingModeIndicator
            style={{
              position: 'absolute',
              left: '23%',
              top: '-22%',
              transform: 'translateX(-50%)'
            }}
          />
          <GripSwitchingModeIndicator
            style={{
              position: 'absolute',
              right: '23%',
              top: '-22%',
              transform: 'translateX(50%)'
            }}
          />
          <DropdownsWrapper>
            {indexes.map((chooseGripsIndex, localIndex) => (
              <React.Fragment key={chooseGripsIndex}>
                {localIndex === 2 ? <div>&nbsp;</div> : null}
                <ArcherElement id={`dropdown-${localIndex}`}>
                  <div style={{ paddingTop: '10px' }}>
                    <DropdownImg
                      disabled={disabled}
                      options={grips.map((grip) => ({
                        value: getGripName(customGripsAllowed, grip),
                        img: gripsImgs.get(grip)
                      }))}
                      selected={{
                        value: getGripName(customGripsAllowed, values[localIndex]),
                        img: gripsImgs.get(values[localIndex])
                      }}
                      responsive
                      onChange={(option) => updateGrips(option, localIndex, nonOpposed)}
                    />
                  </div>
                </ArcherElement>
              </React.Fragment>
            ))}
          </DropdownsWrapper>
        </div>
      </Card>
    </ArcherContainer>
  );
};

export default GraphChooserTree;
