/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import * as Sentry from '@sentry/react';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import AccountDetailsForm from 'adp-panel/pages/Account/AccountDetailsForm';
import { NotificationFactory } from 'lib/NotificationFactory';
import { SubmitHandler } from 'react-hook-form';
import { useMfaMethod, useMfaUpdate } from 'hooks/useAuthentication';
import { useTranslation } from 'react-i18next';
import { MfaOptions, SetMfaStatusPayload } from '../../api/authentication/authentication.types';
import { UserUpdatePayload } from '../../api/users/users.types';
import { useChangeUserPhone, useUserMe, useUserUpdate } from '../../hooks/api/useUsers';
import { useLocation } from 'react-router-dom';
import ConfirmationLoader from 'adp-panel/layouts/ConfirmationLoader';
import { default as DefaultLayoutConfigurator } from 'configurator/layouts/DefaultLayout';
import { ref } from 'yup';

export type Inputs = {
  name: string;
  email: string;
  phone: string | undefined;
  phone_country: string | null;
  password: string;
  language: any;
  clinicName: string | undefined;
  clinicLocation: string | undefined;
  addressLine1: string | undefined;
  addressLine2: string | undefined;
  mfaEnabled: boolean;
  mfaMethod: any;
};

const AccountDetails = () => {
  const { t, i18n } = useTranslation('account');
  const { result: accountDetails, isLoading: isLoadingUserDetails, refetch } = useUserMe();
  const { result: mfaStatus, isLoading: isLoadingMfaStatus } = useMfaMethod();
  const { mutateAsync: updateUser, isLoading: isLoadingUserUpdate } = useUserUpdate();
  const { mutateAsync: updateMfa, isLoading: isLoadingMfaUpdate } = useMfaUpdate();
  const { mutateAsync: updateUserPhone, isLoading: isLoadingPhoneUpdate } = useChangeUserPhone();
  const location = useLocation();
  const { configuratorLayout = false } = location.state || {};

  const handlePhoneChange = async (phone, phoneWithoutPrefix, mfaData) => {
    if (phone !== undefined && phoneWithoutPrefix?.length === 0) {
      if (mfaData.method === MfaOptions.sms) {
        NotificationFactory.errorNotification(
          t(
            'notifications:component.account_details.user_phone_unable_to_delete_mfa_sms',
            'Unable to delete phone, change 2fa to email or disable 2fa'
          )
        );
      } else {
        await updateUserPhone({ userId: accountDetails.id, phone: '0', phone_country: '  ' });
        refetch();
      }
    } else {
      NotificationFactory.errorNotification(
        t(
          'notifications:component.account_details.user_phone_redirect_to_verify',
          'To change phone number use verify button'
        )
      );
    }
  };

  const handleSubmit: SubmitHandler<Inputs> = async (data) => {
    const {
      name = undefined,
      addressLine1 = undefined,
      addressLine2 = undefined,
      clinicName = undefined,
      clinicLocation = undefined,
      language = undefined,
      mfaEnabled = undefined,
      mfaMethod,
      phone = undefined
    } = data;

    const userData = {
      id: accountDetails.id,
      address1: addressLine1,
      address2: addressLine2,
      clinic_name: clinicName,
      clinic_location: clinicLocation,
      ...(name && { name }),
      ...(language && { language: language.code })
    } as UserUpdatePayload;

    const mfaData = {
      enabled: mfaEnabled ? 1 : 0,
      ...(mfaMethod && mfaEnabled && { method: mfaMethod })
    } as SetMfaStatusPayload;

    const phoneWithoutPrefix = phone?.replace(/^\+\d+-/, '');
    const isAccountDetailsEmpty = accountDetails.phone.length === 0;
    const noPhonesInData = isAccountDetailsEmpty && phoneWithoutPrefix?.length === 0;

    if (!noPhonesInData && accountDetails.phone !== phone)
      handlePhoneChange(phone, phoneWithoutPrefix, mfaData);

    const hasUserDataChanged = Object.keys(userData).some(
      (key) => userData[key] !== accountDetails[key]
    );

    if (hasUserDataChanged) {
      try {
        await updateUser(userData);
        if (userData.language) {
          i18n.changeLanguage(language.code);
        }
        refetch();
      } catch (e) {
        Sentry.captureException(e);
        console.log(e);
      }
    }

    if (
      mfaData.enabled !== accountDetails.mfa_enabled ||
      (mfaData?.method && mfaData?.method !== accountDetails.mfa_method)
    ) {
      try {
        await updateMfa(mfaData);
        refetch();
      } catch (e) {
        Sentry.captureException(e);
        console.log(e);
      }
    }
  };

  const isLoadingUpdate = isLoadingUserUpdate || isLoadingMfaUpdate || isLoadingPhoneUpdate;
  const isLoadingData = isLoadingUserDetails || isLoadingMfaStatus;

  if (isLoadingData) return <ConfirmationLoader fullScreen />;

  return configuratorLayout ? (
    <DefaultLayoutConfigurator>
      <FormContentWrapper
        title={t('account:component.account_details.form.title', 'Account settings')}
        small>
        <AccountDetailsForm
          onSubmit={handleSubmit}
          isLoadingUpdate={isLoadingUpdate}
          accountDetails={accountDetails}
          updateUserPhone={updateUserPhone}
          updateMfa={updateMfa}
          mfaStatus={mfaStatus}
        />
      </FormContentWrapper>
    </DefaultLayoutConfigurator>
  ) : (
    <DefaultLayout>
      <FormContentWrapper
        title={t('account:component.account_details.form.title', 'Account settings')}
        small>
        <AccountDetailsForm
          onSubmit={handleSubmit}
          isLoadingUpdate={isLoadingUpdate}
          accountDetails={accountDetails}
          updateUserPhone={updateUserPhone}
          updateMfa={updateMfa}
          mfaStatus={mfaStatus}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};
export default AccountDetails;
