import * as Sentry from '@sentry/react';
import {
  useChangePasswordWithToken,
  useVerifyPasswordChangeToken
} from 'adp-panel/hooks/api/useUsers';
import { useQuery } from 'adp-panel/hooks/useQuery';
import AuthLayout from 'adp-panel/layouts/AuthLayout';
import ConfirmationLoader from 'adp-panel/layouts/ConfirmationLoader';
import { FORGOTTEN_PASSWORD, LOGIN } from 'constants/routes';
import { useEffect, useState } from 'react';
import React from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card/Card';
import * as yup from 'yup';
import LoaderButton from '../../../components/LoaderButton/LoaderButton';
import { AuthTitle, AuthWrapperBox } from '../styled';
import { ButtonWrapper, Link } from './styled';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import CustomPasswordField from 'components/FormFields/CustomPasswordField';
import PasswordStrength from 'configurator/components/atoms/PasswordStrength/PasswordStrength';
import { VALIDATOR_TEXT } from 'constants/validatorText';
import { isStrongPassword } from 'configurator/views/Register/utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

interface FormValues {
  password: string | null;
  confirmPassword: string;
}

const SetUpPass = () => {
  const query = useQuery();
  const { t } = useTranslation('auth');
  const token = query.get('token');
  const email = query.get('email');
  const navigate = useNavigate();
  const [isTokeValid, setIsTokenValid] = useState(true);
  const { mutateAsync: changePassword, isLoading: isLoadingChangePassword } =
    useChangePasswordWithToken();
  const { mutate: verifyToken, isLoading } = useVerifyPasswordChangeToken();

  const setUpPassSchema = yup.object().shape({
    password: yup
      .string()
      .required('')
      .test('valid-pass', '', (value) => {
        if (value === undefined || value === null || value === '') return true;

        return isStrongPassword(value);
      })
      .nullable(),
    confirmPassword: yup
      .string()
      .required(VALIDATOR_TEXT.REQUIRED)
      .test('passwords-match', VALIDATOR_TEXT.PASSWORDS_MUST_MATCH, function (value) {
        const parentPassword = this.parent.password;
        if (parentPassword === undefined || parentPassword === null || parentPassword === '')
          return true;

        return parentPassword === value;
      })
  });

  const {
    handleSubmit: handleSubmitNewPass,
    control,
    watch
  } = useForm<FormValues>({
    resolver: yupResolver(setUpPassSchema),
    mode: 'onChange',
    defaultValues: {
      password: ''
    }
  });

  const passwordWatch = watch('password');

  const handleChange = async (e: any) => {
    try {
      if (!token || !email) {
        return;
      }
      await changePassword({
        token,
        email,
        password: e.password
      });

      navigate(LOGIN);
    } catch (error) {
      Sentry.captureException(error);
      console.log('error', error);
    }
  };

  useEffect(() => {
    const verify = async () => {
      if (!token || !email) {
        return;
      }
      verifyToken(
        { token, email },
        {
          onError: (error: any) => {
            if (error.response.status === 400) {
              setIsTokenValid(false);
            }
          },
          onSuccess: () => {
            setIsTokenValid(true);
          }
        }
      );
    };
    verify();
  }, [token, email]);

  if (isLoading) {
    return <ConfirmationLoader fullScreen />;
  }

  return (
    <AuthLayout>
      <Card>
        {!isTokeValid && (
          <AuthWrapperBox>
            <AuthTitle>{t('auth:component:setup_pass.title', 'Set up password')}</AuthTitle>
            <p>
              {t('auth:component.setup_pass.text1', 'Your token has been used or has expired.')}
            </p>
            <br />
            <p>
              {t(
                'auth:component.setup_pass.text2',
                'If you would like to generate a new token, please go'
              )}{' '}
              <Link onClick={() => navigate(FORGOTTEN_PASSWORD)}>
                {t('auth:component.setup_pass.link.after', 'here')}
              </Link>
            </p>
          </AuthWrapperBox>
        )}
        {isTokeValid && (
          <AuthWrapperBox>
            <AuthTitle>{t('auth:component:setup_pass.title', 'Set up password')}</AuthTitle>
            <form onSubmit={handleSubmitNewPass(handleChange)}>
              <FormWrapper>
                <CustomPasswordField
                  label={t('auth:component.register.form.password', 'Password')}
                  id='password'
                  control={control}
                  noValidationMessage
                />
                <PasswordStrength
                  //@ts-ignore
                  password={passwordWatch}
                  minLength={8}
                  numbers
                  specialChars
                  upperCase
                />
                <CustomPasswordField
                  label={t('auth:component.register.form.repassword', 'Re-type Password')}
                  id='confirmPassword'
                  control={control}
                />
                <ButtonWrapper>
                  <CustomButton
                    data-testid='cancel-save-password'
                    color='light'
                    onClick={() => navigate(LOGIN)}
                    type={'button'}>
                    {t('auth:component:setup_pass.button.cancel', 'Back to login')}
                  </CustomButton>
                  <CustomButton
                    data-testid='save-password'
                    type={'submit'}
                    disabled={isLoadingChangePassword}>
                    {t('auth:component.setup_pass.button.save_password', 'Save password')}
                    {isLoadingChangePassword && <LoaderButton />}
                  </CustomButton>
                </ButtonWrapper>
              </FormWrapper>
            </form>
          </AuthWrapperBox>
        )}
      </Card>
    </AuthLayout>
  );
};

export default SetUpPass;
