import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import FormComboBox from '../../components/FormInput/FormComboBox';
import React from 'react-dom';
import FormTextArea from '../../components/FormInput/FormTextArea';
import { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router-dom';
import { requiredValidator } from '../../components/FormInput/validators';
import { useMutation } from '@tanstack/react-query';
import { FormButtonsWrapper } from './styled';
import { useVersions } from '../../hooks/api/useVersions';
import { createRelease } from '../../api/releaseNote/releaseNote';
import { VersionEntry, VersionTypeOption } from '../../api/versions/version.types';
import { ReleaseNoteCreatePayload } from '../../api/releaseNote/releaseNote.types';
import { RELEASES } from '../../../constants/routes';
import { mapErrorMessage } from '../../utils/notifications';
import LoaderButton from '../../components/LoaderButton/LoaderButton';
import { NotificationFactory } from 'lib/NotificationFactory';
import i18n from 'i18n';

type VersionType = 'DeviceModel' | 'SoftwareVersion' | 'FirmwareVersion' | 'PCBVersion';

interface VersionTypeList {
  id: VersionType;
  label: string;
}

const ReleaseNoteAdd = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState<{ type: VersionTypeOption }>({
    type: 'DeviceModel'
  });
  const [versions, setVersions] = useState<VersionEntry[]>([]);
  const [formState, setFormState] = useState(1);
  const versionPackage = useVersions();

  const { mutate: createReleaseNote } = useMutation(createRelease, {
    onError(e) {
      mapErrorMessage(e);
    },
    onSuccess() {
      NotificationFactory.successNotification(
        i18n.t('common:api_notification.relese_note_added', 'Release note was added')
      );
    }
  });

  const onSubmit = async (e: any) => {
    const { version, version_type, release_desc } = e;
    await createReleaseNote({
      version_type: version_type.id,
      version_id: version.id,
      ...(release_desc && { description: release_desc })
    } as ReleaseNoteCreatePayload);
    navigate(RELEASES);
  };

  const handleTypeChange = ({ value }: { value: VersionTypeList }) => {
    setSelectedType({ type: value.id });
    setVersions(versionPackage[value.id]);
  };

  useEffect(() => {
    setFormState((prev) => prev + 1);
  }, [selectedType]);

  const typeList = [
    { id: 'DeviceModel', label: 'Device Model' },
    { id: 'SoftwareVersion', label: 'Software' },
    { id: 'FirmwareVersion', label: 'Firmware' },
    { id: 'PCBVersion', label: 'PCB' }
  ];

  const isLoading = false;
  return (
    <DefaultLayout>
      <FormContentWrapper title={'Add release'} small>
        <Form
          onSubmit={onSubmit}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <Field
                id={'version_type'}
                label={'Type'}
                name={'version_type'}
                component={FormComboBox}
                data={typeList}
                textField='label'
                textId='id'
                validator={requiredValidator}
                onChange={(e) => {
                  handleTypeChange(e);
                  formRenderProps.onChange('version', { value: null });
                }}
              />
              <Field
                id={'version'}
                label={'Version'}
                name={'version'}
                component={FormComboBox}
                data={versions}
                key={formState}
                textField='name'
                validator={requiredValidator}
              />
              <Field
                id='release_desc'
                name='release_desc'
                key='release_desc'
                label='Description'
                component={FormTextArea}
                rows={3}
                optional={true}
                maxLength={200}
              />
              <FormButtonsWrapper className='k-form-buttons'>
                <Button onClick={() => navigate(-1)} type='button'>
                  Cancel
                </Button>
                <Button
                  themeColor='primary'
                  type='submit'
                  disabled={!formRenderProps.allowSubmit || isLoading}>
                  Add release
                  {isLoading && <LoaderButton />}
                </Button>
              </FormButtonsWrapper>
            </FormElement>
          )}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default ReleaseNoteAdd;
