import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import React from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useVersionCompatibilityCreate } from '../../hooks/api/useVersions';
import { useProductFeatures } from '../../hooks/api/useProductFeatures';
import { ProductFeatureEntry } from '../../api/productFeature/productFeature.types';
import { RELEASES } from '../../../constants/routes';
import ReleaseNoteCompatibilityForm from './ReleaseNoteCompatibilityForm';
import * as Sentry from '@sentry/react';
import { NotificationFactory } from 'lib/NotificationFactory';
import i18n from 'i18n';

const ReleaseNoteAdd = () => {
  const navigate = useNavigate();
  const { result: features } = useProductFeatures();
  const { mutateAsync: createCompatibility, isLoading } = useVersionCompatibilityCreate();

  const onSubmit = async (e: any) => {
    try {
      const { device_model, software_version, pcb_version, firmware_version, full_compatibility } =
        e;
      const featuresSelected: { feature_id: number; compatible: number }[] = [];
      if (features.length > 0) {
        features.forEach((item: ProductFeatureEntry) => {
          featuresSelected.push({
            feature_id: item.id,
            compatible: full_compatibility
              ? (e[`feature_${item.id}`] = 1)
              : e[`feature_${item.id}`]
                ? Number(e[`feature_${item.id}`])
                : 0
          });
        });
      }

      await createCompatibility({
        device_models: device_model.map((item: any) => item.id),
        software_versions: software_version.map((item: any) => item.id),
        firmware_versions: firmware_version.map((item: any) => item.id),
        pcb_versions: pcb_version.map((item: any) => item.id),
        is_fully_compatible: Number(full_compatibility),
        ...(featuresSelected.length > 0 && { features: featuresSelected })
      });

      navigate(RELEASES);
    } catch (error) {
      Sentry.captureException(error);

      NotificationFactory.errorNotification(
        i18n.t('common:api_notification.create_comability_fail', 'Failed to create compatibility')
      );
    }
  };

  return (
    <DefaultLayout>
      <FormContentWrapper
        title={i18n.t('common:release_note_add_title', 'Add new compatibility')}
        small>
        <ReleaseNoteCompatibilityForm onSubmit={onSubmit} isLoading={isLoading} />
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default ReleaseNoteAdd;
