import React from 'react';
import { IconButton, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Controller, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import { commonFormStyles, LabelStyle, LabelWrapper } from './commonStyles';

type ValidateResult = string | boolean | undefined;

interface ValidationMessageWithValues {
  key: string;
  values?: Record<string, any>;
}

interface PasswordValidationValues {
  messages: ValidationMessageWithValues[];
}

interface PasswordValidationMessage {
  key: string;
  values: PasswordValidationValues;
}

type ValidationMessage = string | ValidationMessageWithValues | PasswordValidationMessage;

interface PasswordFieldError extends Omit<FieldError, 'message' | 'types'> {
  message?: ValidationMessage;
  types?: Record<string, ValidationMessage>;
}

interface PasswordFieldProps {
  id: string;
  label: string;
  control: any;
  optional?: boolean;
  disabled?: boolean;
  showPasswordIcon?: boolean;
  helperText?: string;
  noValidationMessage?: boolean;
}

const formatValidationMessage = (
  error: PasswordFieldError | undefined,
  t: TFunction,
  noValidationMessage = false
): string => {
  if (!error || noValidationMessage) return '';

  const message = error.message;
  if (!message) return '';

  const isPasswordValidation = (msg: ValidationMessage): msg is PasswordValidationMessage => {
    return (
      typeof msg === 'object' &&
      'values' in msg &&
      msg.values !== undefined &&
      'messages' in msg.values &&
      Array.isArray(msg.values.messages)
    );
  };

  if (isPasswordValidation(message)) {
    const translatedMessages = message.values.messages.map((msg) => t(msg.key, msg.values));
    return `${t(message.key)} ${translatedMessages.join(', ')}`;
  }

  if (typeof message === 'string') {
    return t(message);
  }

  return t(message.key, message.values);
};

const CustomPasswordField: React.FC<PasswordFieldProps> = ({
  id,
  label,
  control,
  optional = false,
  disabled = false,
  showPasswordIcon = true,
  helperText = '',
  noValidationMessage = false
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const { t } = useTranslation();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => {
        const error = fieldState.error as PasswordFieldError;
        const validationMessage = formatValidationMessage(error, t, noValidationMessage);

        return (
          <div style={{ flex: '1' }}>
            <InputLabel
              shrink={false}
              htmlFor={id}
              sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
              <LabelWrapper>
                <Typography sx={{ ...LabelStyle }}>{label}</Typography>
                {optional && (
                  <Typography>({t('common:password_field.optional_label', 'Optional')})</Typography>
                )}
              </LabelWrapper>
            </InputLabel>
            <TextField
              {...field}
              id={id}
              fullWidth
              error={Boolean(error)}
              helperText={noValidationMessage ? '' : validationMessage || helperText}
              size='small'
              disabled={disabled}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: showPasswordIcon ? (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ) : null
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default CustomPasswordField;
