import { useState, useEffect } from 'react';

const useInvitationValidation = (exp: string | null) => {
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (!exp || exp === null) return;
    const test = parseInt(exp, 10);
    const currentTime = Math.floor(Date.now() / 1000);
    setIsExpired(test < currentTime);
  }, [exp]);

  return isExpired;
};

export default useInvitationValidation;
