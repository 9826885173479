import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TherapyLogo from 'assets/aether-logo.png';
import { DASHBOARD } from 'constants/routes';
import CustomButton from 'components/Button/CustomButton';
import { useTranslation } from 'react-i18next';

export const ErrorContainer = styled.div`
  padding-top: 35vh;
  text-align: center;

  p {
    font-size: ${({ theme }) => theme.fontSize.pageTitleMobile};

    & + p {
      margin-top: ${({ theme }) => theme.dimensions.spacing / 2}px;
    }
  }
`;

export const StyledLogo = styled.img`
  margin-bottom: ${({ theme }) => theme.dimensions.spacing * 1.5}px;
  width: 150px;
`;

export const ErrorComponent = ({
  message,
  allowRedirect = false,
  innerComponent = false
}: {
  message?: ReactNode;
  allowRedirect?: boolean;
  innerComponent?: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const inner = (
    <>
      <StyledLogo src={TherapyLogo} />
      <p>We are sorry.</p>
      <p>Something went wrong.</p>
      {message && <p>{message}</p>}
      {allowRedirect && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CustomButton sx={{ marginTop: '10px' }} onClick={() => navigate(DASHBOARD)}>
            {t('common:error_component.home', 'Home')}
          </CustomButton>
        </div>
      )}
    </>
  );

  if (innerComponent)
    return (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}>
        {inner}
      </div>
    );

  return <ErrorContainer>{inner}</ErrorContainer>;
};
