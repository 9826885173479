import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { MODALS } from 'configurator/views/Modals';
import { useReplayStore } from 'configurator/reducers/replayStore';
import { getModesConfigForDevice } from 'configurator/api/modes/modes';
import { CHOOSE_GRIPS } from 'constants/routes';
import useBluetooth from 'configurator/hooks/bluetooth/useConnect';
import useRemoteSession from 'configurator/hooks/useRemoteSession';
import { useUiStore } from 'configurator/reducers/uiStore';
import ConfirmationLoader from '../../layouts/ConfirmationLoader/ConfirmationLoader';
import useUserData from 'hooks/useUserData';
import { getCompatibility } from 'utils/utils';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export interface SessionStartEvent {
  clinicianId: number;
  amputeeId: number;
  sessionToken: string;
  sessionId: number;
}

export interface TypedCustomEvent<T> extends CustomEvent {
  detail: T;
}

const DeviceInfo = () => {
  const navigate = useNavigate();
  const openModal = useUiStore((state) => state.openModal);
  const { t } = useTranslation();
  const { getDeviceInfoAPI, setItemDeviceInfo } = useDeviceInfoStore((state) => ({
    getDeviceInfoAPI: state.getDeviceInfoAPI,
    setItemDeviceInfo: state.setItemDeviceInfo
  }));
  const resetToDefault = useReplayStore((state) => state.resetToDefault);
  const setItemConfigStore = useConfigStore((state) => state.setItemConfigStore);
  const { getInitialConfigAPI } = useConfigStore((state) => ({
    getInitialConfigAPI: state.getInitialConfigAPI
  }));
  const { bluetoothConnect } = useBluetooth();
  const { data: userData } = useUserData();
  const { enableLiveSession } = useRemoteSession();
  const [deviceDataReady, setDeviceDataReady] = useState(false);
  const [sessionReady, setSessionReady] = useState(false);
  const [repairReady, setRepairReady] = useState(false);

  const location = useLocation();
  const {
    serial = null,
    bluetoothId = null,
    deviceId = null,
    repair = null,
    amputeeId = null,
    token = null,
    sessionId = null,
    modeId = null,
    connect = null,
    checkPairing = null
  } = location.state || {};
  const sessionToken = token;

  const sessionDataReady = sessionId && amputeeId && deviceId && userData && sessionToken;
  const isRepair = repair;

  useEffect(() => {
    const initiateDevice = async ({ deviceId }: { deviceId: number }) => {
      setItemDeviceInfo('deviceId', deviceId);
      const modesData = await getModesConfigForDevice({ deviceId });
      await getDeviceInfoAPI();
      const slotSet = modeId
        ? Number(modeId)
        : modesData.find((mode) => mode.active === 1)?.slot || 0;
      setItemConfigStore('slotSelected', slotSet);
      resetToDefault();
    };

    if (deviceId) {
      initiateDevice({
        deviceId: Number(deviceId)
      });
    }

    setDeviceDataReady(true);
  }, [bluetoothId, serial, deviceId, amputeeId, modeId]);

  useEffect(() => {
    const handlePrepareSession = async (sessionToken, sessionId, amputeeId, clinicianId) => {
      await enableLiveSession(sessionToken, sessionId, amputeeId, clinicianId);
      await getInitialConfigAPI();
      resetToDefault();
      setSessionReady(true);
    };

    if (sessionDataReady) {
      handlePrepareSession(sessionToken, sessionId, amputeeId, userData.id);
    }
  }, [sessionDataReady]);

  useEffect(() => {
    if (isRepair) {
      openModal(MODALS.repair);
      setRepairReady(true);
    }
  }, [isRepair]);

  useEffect(() => {
    const prepareMainView = async () => {
      const {
        isPcbUpgradeNeeded,
        isFirmwareUpdateAvailable,
        isFirmwareUpdateNeeded,
        firmwareName,
        latestFirmware
      } = await getCompatibility({ deviceId });

      if (isPcbUpgradeNeeded) {
        toast(
          t('configurator:notification.pcb_upgrade_needed', {
            version: process.env.REACT_APP_SOFTWARE_VERSION,
            defaultValue:
              'This device is not fully compatible with software version {{version}}, please contact support to upgrade it'
          }),
          { duration: 60000, icon: '⚠️', id: 'isPcbUpgradeNeeded' }
        );
      }

      if (isFirmwareUpdateNeeded) {
        toast(
          t('configurator:notification.firmware_upgrade_needed', {
            firmware: firmwareName,
            version: process.env.REACT_APP_SOFTWARE_VERSION,
            latestFirmware: latestFirmware,
            defaultValue:
              'Current device firmware {{firmware}} is not fully compatible with software version {{version}}, please update to latest firmware {{latestFirmware}}'
          }),
          { duration: 60000, icon: '⚠️', id: 'isFirmwareUpdateNeeded' }
        );
      }

      if (isFirmwareUpdateAvailable) {
        toast(
          t('configurator:notification.firmware_update_available', {
            latestFirmware: latestFirmware,
            defaultValue:
              'There is a new firmware available, please update to latest version {{latestFirmware}}'
          }),
          { duration: 60000, icon: '⚠️', id: 'isFirmwareUpdateAvailable' }
        );
      }
    };
    if (deviceDataReady) {
      if (sessionToken && !sessionReady) return;
      if (isRepair && !repairReady) return;

      navigate(CHOOSE_GRIPS, { replace: true });
      prepareMainView();
      if (connect) bluetoothConnect(bluetoothId ? bluetoothId : null, false, checkPairing);
    }
  }, [connect, deviceDataReady, sessionReady, sessionToken, isRepair, repairReady]);

  return <ConfirmationLoader />;
};

export default DeviceInfo;
