import { Alert, Modal, Typography } from '@mui/material';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import CustomButton from 'components/Button/CustomButton';
import { FormWrapper } from 'components/FormFields/commonStyles';
import { useTranslation } from 'react-i18next';
import { useConfigStore } from 'configurator/reducers/configStore';
import { ModalStyle } from 'adp-panel/components/Modals/styles';
import {
  IndicatorIconWrapper,
  IndicatorInnerWrapper,
  IndicatorWrapper,
  UpdateButtonsWrapper
} from './FirmwareModal/styled';
import ProcedureIndicator, {
  PROCEDURE_INDICATOR_VARIANTS
} from 'configurator/components/atoms/ProcedureIndicator/ProcedureIndicator';
import {
  PROCEDURE_STATUS,
  procedureMapping,
  procedureNamesMapping,
  procedureUserNameMapping
} from './FirmwareModal/utils';
import { ProcedureTypes } from 'bluetooth/Bluetooth/Procedures';
import { useEffect, useState } from 'react';
import ProcedureFingersTable from 'configurator/components/molecules/ProcedureTable/ProcedureTable';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';

const AfterUpdateModal = ({ handleModalClose }) => {
  const { t } = useTranslation();
  const { handleProcedure } = useConfigStore((state) => ({
    handleProcedure: state.handleProcedure
  }));
  const { closeModal } = useUiStore((state) => ({
    closeModal: state.closeModal
  }));
  const { bluetoothId } = useDeviceInfoStore((state) => ({
    bluetoothId: state.bluetoothId
  }));
  const remoteSession = useLiveConfiguratorStore((state) => state.enabled);

  const [proceduresStarted, setProceduresStarted] = useState(false);
  const [proceduresStatus, setProceduresStatus] = useState<{
    softGrip: PROCEDURE_STATUS;
    fingerSpeed: PROCEDURE_STATUS;
    info: undefined | { reply: any; procedure: ProcedureTypes };
  }>({
    softGrip: PROCEDURE_STATUS.UNDEFINED,
    fingerSpeed: PROCEDURE_STATUS.UNDEFINED,
    info: undefined
  });

  const performProcedure = async (procedureType: ProcedureTypes) => {
    const procedureName = procedureNamesMapping[procedureType];
    try {
      setProceduresStatus((prev) => ({ ...prev, [procedureName]: PROCEDURE_STATUS.RUNNING }));
      const procedureReply = await handleProcedure({
        procedureNumber: procedureType,
        preventInput: false,
        showPreventInputMessage: false
      });
      if (!procedureReply) return;

      const parsedProcedureReply = procedureMapping[procedureType](procedureReply.procedureReply);
      const procedureErrorFound = parsedProcedureReply?.status.includes(0);

      if (procedureErrorFound) {
        setProceduresStatus((prev) => ({
          ...prev,
          [procedureName]: PROCEDURE_STATUS.FAILED,
          info: { reply: parsedProcedureReply, procedure: procedureType }
        }));
        return false;
      }
      setProceduresStatus((prev) => ({ ...prev, [procedureName]: PROCEDURE_STATUS.SUCCESS }));
      return true;
    } catch (e) {
      setProceduresStatus((prev) => ({
        ...prev,
        [procedureName]: PROCEDURE_STATUS.FAILED,
        info: { reply: null, procedure: procedureType }
      }));
      return false;
    }
  };

  const decideVariantProcedure = (procedureStatus: PROCEDURE_STATUS) => {
    if (procedureStatus === PROCEDURE_STATUS.UNDEFINED) {
      return PROCEDURE_INDICATOR_VARIANTS.waiting;
    }
    if (procedureStatus === PROCEDURE_STATUS.RUNNING) {
      return PROCEDURE_INDICATOR_VARIANTS.in_progress;
    }
    if (procedureStatus === PROCEDURE_STATUS.SUCCESS) {
      return PROCEDURE_INDICATOR_VARIANTS.done;
    }
    if (procedureStatus === PROCEDURE_STATUS.FAILED) {
      return PROCEDURE_INDICATOR_VARIANTS.failed;
    }
  };

  const procedureFailed =
    proceduresStatus.fingerSpeed === PROCEDURE_STATUS.FAILED ||
    proceduresStatus.softGrip === PROCEDURE_STATUS.FAILED;

  const allProceduresSucceeded =
    proceduresStatus.fingerSpeed === PROCEDURE_STATUS.SUCCESS &&
    proceduresStatus.softGrip === PROCEDURE_STATUS.SUCCESS;

  const performAfterUpdate = async () => {
    const softGripStatus = await performProcedure(ProcedureTypes.calibrateSoftGrip);

    if (!softGripStatus) return;

    const fingerSpeedStatus = await performProcedure(ProcedureTypes.calibrateFingersSpeed);

    if (!fingerSpeedStatus) return;

    localStorage.removeItem(`calibrationNeeded-${bluetoothId}`);
  };

  const handleSessionCalibrationState = (data) => {
    const status = data?.detail?.data?.status;
    const type = data?.detail?.data?.type;

    let procedureName;
    if (type === 'soft_grip') procedureName = 'softGrip';
    if (type === 'finger_speed') procedureName = 'fingerSpeed';

    let procedureStatus;

    if (status === 'in_progress') procedureStatus = PROCEDURE_STATUS.RUNNING;
    if (status === 'successful') procedureStatus = PROCEDURE_STATUS.SUCCESS;
    if (status === 'failed') procedureStatus = PROCEDURE_STATUS.FAILED;

    setProceduresStatus((prev) => ({ ...prev, [procedureName]: procedureStatus }));
  };

  const procedureInfo = proceduresStatus?.info;

  useEffect(() => {
    if (!proceduresStarted) {
      closeModal(MODALS.firmware);
      if (!remoteSession) performAfterUpdate();
      setProceduresStarted(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener(`calibration_state`, handleSessionCalibrationState);

    return function clean() {
      window.removeEventListener(`calibration_state`, handleSessionCalibrationState);
    };
  }, []);

  return (
    <Modal open={true} sx={{ width: '480px', ...ModalStyle }}>
      <FormContentWrapper
        title={t('configurator:component.after_update_modal.title', 'After update')}>
        <div>
          <FormWrapper>
            <div style={{ display: 'flex', gap: '16px', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <IndicatorInnerWrapper>
                  <Typography>
                    {t(
                      'configurator:component.after_update_modal.soft_grip_calibration',
                      'Soft grip calibration'
                    )}
                  </Typography>
                  <IndicatorIconWrapper>
                    <ProcedureIndicator
                      variant={decideVariantProcedure(proceduresStatus.softGrip)}
                    />
                  </IndicatorIconWrapper>
                </IndicatorInnerWrapper>
                <IndicatorInnerWrapper>
                  <Typography>
                    {t(
                      'configurator:component.after_update_modal.finger_speed_calibration',
                      'Finger speed calibration'
                    )}
                  </Typography>
                  <IndicatorIconWrapper>
                    <ProcedureIndicator
                      variant={decideVariantProcedure(proceduresStatus.fingerSpeed)}
                    />
                  </IndicatorIconWrapper>
                </IndicatorInnerWrapper>
              </div>
              {procedureFailed && remoteSession && (
                <>
                  <Alert severity='error'>
                    {t(
                      'configurator:component.after_update_modal.calibration_failed',
                      'The calibration procedure failed.'
                    )}
                  </Alert>
                  <UpdateButtonsWrapper>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <CustomButton onClick={handleModalClose}>
                        {t('configurator:component.after_update_modal.buttons.close', 'Close')}
                      </CustomButton>
                    </div>
                  </UpdateButtonsWrapper>
                </>
              )}
              {procedureFailed && procedureInfo && (
                <>
                  <Alert severity='error'>
                    {t('configurator:component.after_update_modal.calibration_failed_inform', {
                      procedure: procedureUserNameMapping[procedureInfo.procedure],
                      defaultValue:
                        'The {{ procedureUserNameMapping[procedureInfo.procedure]}} calibration procedure failed. Please try again.'
                    })}
                  </Alert>
                  {procedureInfo?.reply && (
                    <ProcedureFingersTable
                      procedureType={procedureInfo.procedure}
                      procedureStatus={procedureInfo.reply.status}
                      procedureValues={
                        procedureInfo.reply[
                          procedureInfo.procedure === ProcedureTypes.calibrateSoftGrip
                            ? 'maxCurrents'
                            : 'speeds'
                        ]
                      }
                    />
                  )}
                  <UpdateButtonsWrapper>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <CustomButton onClick={handleModalClose} variant='text'>
                        {t('configurator:component.after_update_modal.buttons.cancel', 'Cancel')}
                      </CustomButton>
                      <CustomButton onClick={performAfterUpdate}>
                        {t('configurator:component.after_update_modal.buttons.retry', 'Try again')}
                      </CustomButton>
                    </div>
                  </UpdateButtonsWrapper>
                </>
              )}
              {allProceduresSucceeded && (
                <UpdateButtonsWrapper>
                  <CustomButton onClick={handleModalClose}>
                    {t('configurator:component.after_update_modal.buttons.close', 'Close')}
                  </CustomButton>
                </UpdateButtonsWrapper>
              )}
            </div>
          </FormWrapper>
        </div>
      </FormContentWrapper>
    </Modal>
  );
};

export default AfterUpdateModal;
