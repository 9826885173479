import { Modal } from '@mui/material';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import CustomButton from 'components/Button/CustomButton';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Box } from '@mui/material';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import { ModalStyle } from '../styles';

const InviteMedicalProfessionalsSuccessModal = ({ handleModalClose }) => {
  const { t } = useTranslation('medical_professionals');

  return (
    <Modal open sx={{ width: '480px', ...ModalStyle }}>
      <FormContentWrapper
        title={t(
          'medical_professionals:invite_medical_professionals_success_modal.title',
          'Invite new Medical Professional'
        )}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '30px'
          }}>
          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#DCFAE6',
              borderRadius: '50%',
              padding: '10px',
              height: '60px',
              width: '60px'
            }}>
            <CheckCircleOutlinedIcon
              sx={{
                color: '#079455',
                fontSize: 30
              }}
            />
          </Box>
          <h2 style={{ margin: '30px 0' }}>
            {t(
              'medical_professionals:invite_medical_professionals_success_modal.info',
              'The clinician(s) have been successfully assigned.'
            )}
          </h2>
          <h3 style={{ margin: '0 0', textAlign: 'center' }}>
            {t(
              'medical_professionals:invite_medical_professionals_success_modal.expired_time',
              'If registration was required, invitation link(s) have been sent and will remain active for 24 hours.'
            )}
          </h3>
          <FormButtonsWrapper>
            <CustomButton
              data-testid='cancel-medical-form'
              type='button'
              color='light'
              onClick={handleModalClose}>
              {t('medical_professionals:invite_medical_professionals_success_modal.info', 'Close')}
            </CustomButton>
          </FormButtonsWrapper>
        </div>
      </FormContentWrapper>
    </Modal>
  );
};

export default InviteMedicalProfessionalsSuccessModal;
