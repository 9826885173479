import { Modal } from '@mui/material';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import CustomButton from 'components/Button/CustomButton';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmailExpiredIcon } from 'assets/email_expired_icon.svg';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import { ModalStyle } from './styles';
import styled from 'styled-components';

const ExpiredInvitationModal = ({ handleModalClose }) => {
  const { t } = useTranslation('auth');

  const StyledHeader = styled.h1`
    margin: 30px 0;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
  `;

  return (
    <Modal open sx={{ width: '480px', ...ModalStyle }}>
      <FormContentWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <EmailExpiredIcon />
          <StyledHeader>
            {t('auth:expired_invitation_modal_title', 'Invitation Expired')}
          </StyledHeader>
          <h3 style={{ margin: '0 0' }}>
            {t(
              'auth:expired_invitation_modal_text',
              'Your invitation has expired because it was active for 24 hours. Ask for the invitation to be sent again.'
            )}
          </h3>
          <FormButtonsWrapper>
            <CustomButton
              data-testid='cancel-medical-form'
              type='button'
              color='light'
              onClick={handleModalClose}>
              {t('auth:invite_medical_professionals_success_modal.info', 'Close')}
            </CustomButton>
          </FormButtonsWrapper>
        </div>
      </FormContentWrapper>
    </Modal>
  );
};

export default ExpiredInvitationModal;
