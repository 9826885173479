import { GoalConditionType, GoalConditionsEntryPayload } from 'adp-panel/api/goals/goals.types';
import dayjs from 'dayjs';
import {
  useUserGoalConditionsCreate,
  useUserGoalUpdate,
  useUserGoalConditionsDelete
} from 'adp-panel/hooks/api/useGoals';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import GoalsForm from './GoalsForm';
import { filterExercises, filterGrips } from './utils';
import { NotificationFactory } from 'lib/NotificationFactory';
import { Modal } from '@mui/material';
import { ModalStyle } from 'adp-panel/components/Modals/styles';
import FormContentWrapper from 'adp-panel/layouts/FormContentWrapper';
import { useTranslation } from 'react-i18next';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';

const EditGoalModal = ({ handleClose }) => {
  const { additionalModalArgs } = useUiStore((state) => ({
    additionalModalArgs: state.additionalModalArgs
  }));

  const additionalArgs = additionalModalArgs(MODALS.editGoalModal)?.args;

  const userId = additionalArgs.userId;
  const goalId = additionalArgs.goalId;
  const conditions = additionalArgs.conditions;
  const isView = additionalArgs.isView;
  const initialValues = additionalArgs.initialValues;

  const { t } = useTranslation('goals');
  const navigate = useNavigate();
  const { mutateAsync: goalEdit, isLoading: isLoadingGoalCreate } = useUserGoalUpdate();
  const { mutateAsync: goalConditionCreate, isLoading: isLoadingGoalConditionCreate } =
    useUserGoalConditionsCreate();
  const { mutateAsync: goalConditionDelete, isLoading: isLoadingGoalConditionDelete } =
    useUserGoalConditionsDelete();

  const isLoading =
    isLoadingGoalCreate || isLoadingGoalConditionCreate || isLoadingGoalConditionDelete;

  const handleAddSubmit = async (values: any) => {
    try {
      const startDate = String(dayjs(values.start_date).format());
      const endDate = String(dayjs(values.start_date).add(values.period, 'day').format());
      const goal = await goalEdit({
        userId,
        goalId,
        start_date: startDate,
        end_date: endDate,
        active: 0
      });
      if (!goalId) return;
      await Promise.all(
        conditions.map((condition: any) =>
          goalConditionDelete({
            userId,
            goalId: goal.id,
            conditionId: condition.id
          })
        )
      );
      const gripsGoals = filterGrips(values);
      const exercisesGoals = filterExercises(values);
      await goalConditionCreate({
        userId,
        goalId: goal.id,
        type: GoalConditionType.switch,
        switches_count: values.switches
      });
      await Promise.all(
        gripsGoals.map((gripGoal: any) =>
          goalConditionCreate({
            userId,
            goalId: goal.id,
            type: GoalConditionType.grip,
            grip_id: gripGoal.id,
            grips_count: gripGoal.performedPerDay
          })
        )
      );
      await Promise.all(
        exercisesGoals.map((exerciseGoal: any) =>
          goalConditionCreate({
            userId,
            goalId: goal.id,
            type: GoalConditionType.exercise,
            exercise_id: Number(exerciseGoal.id),
            exercise_frequency: exerciseGoal.frequency,
            exercise_count: exerciseGoal.performedPerDay
          })
        )
      );
      NotificationFactory.successNotification(t('goals:form_goal.modal.edited', 'Goal edited'));
      navigate(-1);
    } catch (e) {
      Sentry.captureException(e);
      console.log('error', e);
      NotificationFactory.errorNotification(
        t('goals:form_goal.modal.fail_edit', 'Failed to edit goal')
      );
    }
  };

  return (
    <Modal open sx={{ width: '700px', ...ModalStyle }}>
      <FormContentWrapper title={t('goals:edit_goal.modal.title', 'Goal details')}>
        <GoalsForm
          isLoading={isLoading}
          handleSubmit={handleAddSubmit}
          isEdit
          isView={isView}
          initialValues={initialValues}
          handleModalClose={handleClose}
        />
      </FormContentWrapper>
    </Modal>
  );
};

export default EditGoalModal;
