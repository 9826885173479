import { CHOOSE_GRIPS } from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import { useUiStore } from 'configurator/reducers/uiStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { useReplayStore } from 'configurator/reducers/replayStore';
import { useTicket } from '../../hooks/api/useTickets';
import ConfirmationLoader from '../../layouts/ConfirmationLoader/ConfirmationLoader';
import {
  SupportTicketAttachmentEntry,
  SupportTicketEntry,
  SupportTicketExtendOptions,
  SupportTicketMessageEntry
} from '../../api/tickets/tickets.types';
import { getTicketConfig } from '../../api/tickets/tickets';

const SupportTicket = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { ticketId = null } = location.state || {};
  const { setItemUiStore } = useUiStore();
  const { setDeviceInfo } = useDeviceInfoStore();
  const { setItemReplayStore, setReplayData, setReplaySettings } = useReplayStore();
  const {
    result: ticket,
    isLoading,
    isError
  } = useTicket({
    ticketId,
    enabled: true,
    params: { extend: [SupportTicketExtendOptions.messagesAttachments] }
  });

  const findAttachmentWithJsonConfig = (ticket: SupportTicketEntry) => {
    if (!ticket) return false;
    if (ticket?.messages?.length === 0) return false;
    // @ts-ignore
    const messageWithAttachments: SupportTicketAttachmentEntry[] = Array.from(ticket.messages)
      .filter((message: SupportTicketMessageEntry) => {
        if (message.attachments === undefined) {
          return false;
        }

        return message.attachments.length !== 0;
      })
      .map((item: SupportTicketMessageEntry) => item.attachments)
      .filter((item) => item !== undefined)
      .flat();

    return Array.from(messageWithAttachments).find(
      (attachment: SupportTicketAttachmentEntry) => attachment.type === 'application/json'
    );
  };

  useEffect(() => {
    const configUrl = get(findAttachmentWithJsonConfig(ticket), 'attachment', undefined);
    if (!ticket || !configUrl) {
      return;
    }
    getTicketConfig(configUrl)
      .then(async (result: AxiosResponse) => {
        setDeviceInfo({
          serial: null,
          bluetoothId: null,
          deviceId: null,
          amputeeId: null
        });
        setItemReplayStore('enabled', true);
        setReplaySettings({
          ticket,
          configUrl
        });
        setReplayData(result.data);
        setItemUiStore('isRecordReplay', true);
        setIsSuccess(true);
        navigate(CHOOSE_GRIPS, { replace: true });
      })
      .catch(() => {
        setDeviceInfo({
          serial: null,
          bluetoothId: null,
          deviceId: null,
          amputeeId: null
        });

        toast.error('Resource failed to load. Invalid attachment file');
        setIsSuccess(false);
        navigate(-1);
      });
  }, [ticket]);

  if (isLoading) {
    return <ConfirmationLoader />;
  }

  if (isError || !isSuccess) {
    return null;
  }

  return <ConfirmationLoader />;
};

export default SupportTicket;
