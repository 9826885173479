import { codeIconMap, CountryCode } from 'adp-panel/constants/countries';
import React, { ReactElement } from 'react';
import { TextField, InputAdornment, Select, MenuItem } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as ValidCheck } from 'assets/check-circle.svg';
import { ReactComponent as InValidCheck } from 'assets/info-circle.svg';

export const countries: { country: CountryCode; code: string; icon: ReactElement | undefined }[] = [
  {
    country: 'US',
    code: '+1',
    icon: codeIconMap.get('US')
  },
  {
    country: 'GB',
    code: '+44',
    icon: codeIconMap.get('GB')
  },
  {
    country: 'PL',
    code: '+48',
    icon: codeIconMap.get('PL')
  },
  {
    country: 'DE',
    code: '+49',
    icon: codeIconMap.get('DE')
  },
  {
    country: 'PT',
    code: '+351',
    icon: codeIconMap.get('PT')
  },
  {
    country: 'FR',
    code: '+33',
    icon: codeIconMap.get('FR')
  },
  {
    country: 'RU',
    code: '+7',
    icon: codeIconMap.get('RU')
  },
  {
    country: 'SE',
    code: '+46',
    icon: codeIconMap.get('SE')
  },
  {
    country: 'ES',
    code: '+34',
    icon: codeIconMap.get('ES')
  },
  {
    country: 'AT',
    code: '+43',
    icon: codeIconMap.get('AT')
  },
  {
    country: 'TR',
    code: '+90',
    icon: codeIconMap.get('TR')
  },
  {
    country: 'IT',
    code: '+39',
    icon: codeIconMap.get('IT')
  },
  {
    country: 'JP',
    code: '+81',
    icon: codeIconMap.get('JP')
  },
  {
    country: 'CN',
    code: '+86',
    icon: codeIconMap.get('CN')
  },
  {
    country: 'IN',
    code: '+91',
    icon: codeIconMap.get('IN')
  },
  {
    country: 'MX',
    code: '+52',
    icon: codeIconMap.get('MX')
  },
  {
    country: 'BR',
    code: '+55',
    icon: codeIconMap.get('BR')
  },
  {
    country: 'AU',
    code: '+61',
    icon: codeIconMap.get('AU')
  },
  {
    country: 'NZ',
    code: '+64',
    icon: codeIconMap.get('NZ')
  },
  {
    country: 'KR',
    code: '+82',
    icon: codeIconMap.get('KR')
  },
  {
    country: 'AE',
    code: '+971',
    icon: codeIconMap.get('AE')
  },
  {
    country: 'CA',
    code: '+1',
    icon: codeIconMap.get('CA')
  },
  {
    country: 'ZA',
    code: '+27',
    icon: codeIconMap.get('ZA')
  },
  {
    country: 'GR',
    code: '+30',
    icon: codeIconMap.get('GR')
  },
  {
    country: 'NL',
    code: '+31',
    icon: codeIconMap.get('NL')
  },
  {
    country: 'BE',
    code: '+32',
    icon: codeIconMap.get('BE')
  },
  {
    country: 'HU',
    code: '+36',
    icon: codeIconMap.get('HU')
  },
  {
    country: 'RO',
    code: '+40',
    icon: codeIconMap.get('RO')
  },
  {
    country: 'CH',
    code: '+41',
    icon: codeIconMap.get('CH')
  },
  {
    country: 'DK',
    code: '+45',
    icon: codeIconMap.get('DK')
  },
  {
    country: 'NO',
    code: '+47',
    icon: codeIconMap.get('NO')
  },
  {
    country: 'LU',
    code: '+352',
    icon: codeIconMap.get('LU')
  },
  {
    country: 'FI',
    code: '+358',
    icon: codeIconMap.get('FI')
  },
  {
    country: 'BG',
    code: '+359',
    icon: codeIconMap.get('BG')
  },
  {
    country: 'LT',
    code: '+370',
    icon: codeIconMap.get('LT')
  },
  {
    country: 'LV',
    code: '+371',
    icon: codeIconMap.get('LV')
  },
  {
    country: 'EE',
    code: '+372',
    icon: codeIconMap.get('EE')
  },
  {
    country: 'UA',
    code: '+380',
    icon: codeIconMap.get('UA')
  },
  {
    country: 'RS',
    code: '+381',
    icon: codeIconMap.get('RS')
  },
  {
    country: 'HR',
    code: '+385',
    icon: codeIconMap.get('HR')
  },
  {
    country: 'CZ',
    code: '+420',
    icon: codeIconMap.get('CZ')
  },
  {
    country: 'SK',
    code: '+421',
    icon: codeIconMap.get('SK')
  },
  {
    country: 'SA',
    code: '+966',
    icon: codeIconMap.get('SA')
  },
  {
    country: 'IL',
    code: '+972',
    icon: codeIconMap.get('IL')
  }
];

const StyledCountryCode = styled.div`
  line-height: 0;
  display: inline-block;
  margin-right: 8px;
  border-radius: 50%;
  transition: linear 0.25s;
  height: auto;
  width: 20px;

  img,
  svg {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
`;

const CountryItem = styled.div`
  display: flex;
  align-items: center;
`;

const PhoneSelectInput = ({
  phoneNumber,
  selectedCountry,
  onCountryChange,
  onPhoneNumberChange,
  onBlur,
  isValidPhoneNumber,
  value,
  noEndAdornment,
  error,
  helperText
}) => {
  return (
    <TextField
      value={phoneNumber}
      onChange={(e) => onPhoneNumberChange(e.target.value)}
      onBlur={onBlur}
      size='small'
      variant='outlined'
      fullWidth
      type='tel'
      error={error}
      helperText={helperText}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position='start'>
              <Select
                value={selectedCountry}
                onChange={(e) => onCountryChange(e.target.value)}
                disableUnderline
                variant='standard'
                sx={{
                  backgroundColor: 'transparent',
                  '& .MuiInput-input': {
                    paddingBottom: '0'
                  },
                  '& .MuiSelect-select': {
                    backgroundColor: 'transparent !important'
                  }
                }}>
                {countries.map((country) => (
                  <MenuItem key={country.country} value={country.country}>
                    <CountryItem>
                      <StyledCountryCode>{country.icon}</StyledCountryCode> {country.code}
                    </CountryItem>
                  </MenuItem>
                ))}
              </Select>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              {noEndAdornment ? '' : isValidPhoneNumber ? <ValidCheck /> : <InValidCheck />}
            </InputAdornment>
          )
        }
      }}
    />
  );
};

export default PhoneSelectInput;
